


















/*
  this menu is built by using the routes in the current module.
  Just add a new route with a new component and it will appear

  avoid the display property in the route to hide the component
  from the menu
*/
import { Component, Vue, Prop } from "vue-property-decorator";
import { adminRoutes as routes } from "../routes";
import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class AdminMenuCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "Menu" }) title: string;
  isActive(routeName: string) {
    return this.$route.name === routeName;
  }
  get isLoading() {
    return this.loading;
  }
  get adminRoutes() {
    return routes.filter((x) => !!x.display);
  }

  async created() {}

  async mounted() {}
}
