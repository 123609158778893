
































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { CreditNoteModel, CreditNoteService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class CreditNoteEditorCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "" }) percentage: number;
  @Prop({ default: 0 }) subscriptionId: number;
  @Prop({ default: () => new CreditNoteModel() }) model: CreditNoteModel;
  @Prop({ default: false }) visible: boolean;
  get invalidCreditNote() {
    return !this.model.netAmount || !this.model.totalAmount || !this.model.creditNoteDate || !this.model.notes;
  }
  get isLoading() {
    return this.loading;
  }
  get dialogTitle() {
    return !!this.model.creditNoteID ? "Edit credit note" : `Add credit note to subscription ${this.subscriptionId}`;
  }

  async saveCreditNote(creditNote: CreditNoteModel) {
    creditNote.subscriptionID = this.subscriptionId;
    const result = await CreditNoteService.saveCreditNote(creditNote);
    result.justUpdated = true;
    this.$emit("save", result);
  }
  onClose() {
    this.$emit("close");
  }
  updateVat() {
    if (!!this.model.netAmount) {
      this.model.vatAmount = Number(this.model.netAmount) * Number(Number(this.percentage) / 100);
      this.model.totalAmount = Number(this.model.vatAmount) + Number(this.model.netAmount);
    }
  }
  async created() {}

  async mounted() {
    this.model.subscriptionID = this.subscriptionId;
  }
}
