






























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { AccountAdminService } from "@/core/services";
import {} from "@/core/components";
import ApiKeyEditDialogCpt from "./ApiKeyEditDialogCpt.vue";

import AppVue from "@/AppVue.vue";
import { PagedCollection, PagedCollectionFilter } from "@/core/models";
import moment from "moment";
class ApiKeyModel {}
@Component({
  components: {
    ApiKeyEditDialogCpt: () => Promise.resolve(ApiKeyEditDialogCpt),
  },
})
export default class ApiKeyListCpt extends AppVue {
  loading: boolean = false;
  results: PagedCollection<ApiKeyModel> = new PagedCollection();
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 25,
  };
  apiKey: any = {
    apiKeyId: null,
    validUntil: "",
    companySearchEnabled: false,
  };
  showEditApiKeyDialog: boolean = false;
  getTypeForDate(date: any) {
    var test = moment(date);

    if (test.isBefore()) return "danger";

    if (test.add(30, "d").isBefore()) return "warning";

    return "success";
  }
  async created() {
    this.updateTitle("API Keys");
  }
  async refresh() {
    this.showEditApiKeyDialog = false;
    this.loading = true;
    var r = (await AccountAdminService.GetAllApiKeys(this.filter)) as PagedCollection<ApiKeyModel>;
    this.results = r;
    this.loading = false;
  }
  async enableApiKey(userId: number, apiKeyId: number) {
    this.loading = true;
    await AccountAdminService.ActivateApiKey(userId, apiKeyId);
    this.refresh();
  }
  async disableApiKey(userId: number, apiKeyId: number) {
    this.loading = true;
    await AccountAdminService.DeactivateApiKey(userId, apiKeyId);
    this.refresh();
  }

  showEditApiKey(item: any) {
    this.apiKey.apiKeyId = item.userApiKeyId;
    this.apiKey.validUntil = moment(item.validUntil).local().format("YYYY-MM-DD");
    this.apiKey.companySearchEnabled = item.companySearchEnabled;
    this.showEditApiKeyDialog = true;
  }

  @Watch("filter.page") async pageUpdate() {
    await this.refresh();
  }
  prevPage() {
    this.loading = true;
    this.filter.page = this.filter.page - 1;
  }
  nextPage() {
    this.loading = true;
    this.filter.page = this.filter.page + 1;
  }
  resetPage() {
    this.loading = true;
    this.filter.page = 1;
  }
  async mounted() {
    await this.refresh();
  }
}
