





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { AccountAdminService } from "@/core/services";
import AppVue from "@/AppVue.vue";
import moment from "moment";

@Component({
  components: {},
})
export default class ApiKeyEditDialogCpt extends AppVue {
  @Prop({ default: false }) visible: boolean;
  @Prop({
    default: {
      apiKeyId: null,
      validUntil: "",
      companySearchEnabled: false,
    },
  })
  apiKey: any;
  internalShowDialog: boolean = false;
  loading: boolean = false;
  internalApiKey: any = {
    apiKeyId: null,
    validUntil: "",
    companySearchEnabled: false,
  };

  @Watch("apiKey", { deep: true })
  apiKeyUpdated() {
    this.internalApiKey = { ...this.apiKey };
  }

  close() {
    this.$emit("close");
  }

  async updateApiKey() {
    this.loading = true;
    try {
      await AccountAdminService.UpdateApiKey(
        this.internalApiKey.apiKeyId,
        moment(this.internalApiKey.validUntil).local().format("YYYY-MM-DD"),
        this.internalApiKey.companySearchEnabled,
      );
      this.$emit("updated");
      this.notify("Success", `API Key updated successfully`, "success");
    } catch {
      this.loading = false;
    }
  }
}
