















































import { Component, Vue, Prop } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import {} from "@/core/components";
import { EditProfileModel } from "@/core/models";
import UserFormCpt from "./UserFormCpt.vue";
import AppVue from "@/AppVue.vue";

@Component({
  components: {
    UserFormCpt: () => Promise.resolve(UserFormCpt),
  },
})
export default class CreateUserCpt extends AppVue {
  profile = new EditProfileModel();
  saveAttempted = false;
  loading: boolean = false;

  get isLoading() {
    return this.loading;
  }

  get isValidProfile() {
    return (
      !!this.profile &&
      !!this.profile.emailAddress &&
      !!this.profile.name &&
      !!this.profile.lastname &&
      !!this.profile.vatCode &&
      !!this.profile.accountName &&
      !!this.profile.accountCode
    );
  }

  formUpdated(updateProfile: EditProfileModel) {
    this.profile = updateProfile;
  }

  async save() {
    // guard
    this.saveAttempted = true;

    if (!this.isValidProfile) {
      return;
    }

    const result = await AccountService.SaveNewUser(this.profile);
    this.profile = result;
    this.notify(
      "User created!",
      `User created. You can now setup their subscription(s) and send their invitation email`,
      "success",
    );
    this.$router.push({
      name: "user-edit",
      params: {
        userProfileId: this.profile.userProfileId.toString(),
      },
    });
  }

  async created() {
    this.updateTitle("Add user");
  }
}
