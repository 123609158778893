









































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  AccountAdminService,
  AccountService,
  AlertService,
  LookupsService,
  ProfileModel,
  SearchService,
  SubscriptionModel,
  SubscriptionService,
} from "@/core/services";
import { DateValue, UserAnalyticsStatsModel, EditProfileModel, UserSessionModel } from "@/core/models";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

import UpdateUserButtonCpt from "./actions/UpdateUserButton.vue";
import DeactivateAccountButtonCpt from "./actions/DeactivateAccountButton.vue";
import ActivateAccountButtonCpt from "./actions/ActivateAccountButton.vue";
import moment from "moment";
import UserProfileCpt from "./UserProfileCpt.vue";
import UserSubscriptionsCpt from "./UserSubscriptionsCpt.vue";
import _ from "lodash";
import UnconfirmEmailAccountButton from "./actions/UnconfirmEmailAccountButton.vue";
import ConfirmEmailAccountButton from "./actions/ConfirmEmailAccountButton.vue";
import UserFormCpt from "./UserFormCpt.vue";
import { roles } from "@/core/constants";
import SetPasswordButtonCpt from "./actions/SetPasswordButtonCpt.vue";
import SetRoleButtonCpt from "./actions/SetRoleButtonCpt.vue";
import UserChartsCpt from "./charts/UserChartsCpt.vue";
import ActivityTabPanelCpt from "./tabs/ActivityTabPanelCpt.vue";
import TrackingAlertsCpt from "./tabs/TrackingAlertsCpt.vue";
import ApiKeyPanelCpt from "./tabs/ApiKeyPanelCpt.vue";

@Component({
  components: {
    UpdateUserButtonCpt: () => Promise.resolve(UpdateUserButtonCpt),
    ActivateAccountButtonCpt: () => Promise.resolve(ActivateAccountButtonCpt),
    DeactivateAccountButtonCpt: () => Promise.resolve(DeactivateAccountButtonCpt),
    UserProfileCpt: () => Promise.resolve(UserProfileCpt),
    UserSubscriptionsCpt: () => Promise.resolve(UserSubscriptionsCpt),
    ConfirmEmailAccountButton: () => Promise.resolve(ConfirmEmailAccountButton),
    UnconfirmEmailAccountButton: () => Promise.resolve(UnconfirmEmailAccountButton),
    UserFormCpt: () => Promise.resolve(UserFormCpt),
    SetPasswordButtonCpt: () => Promise.resolve(SetPasswordButtonCpt),
    SetRoleButtonCpt: () => Promise.resolve(SetRoleButtonCpt),
    UserChartsCpt: () => Promise.resolve(UserChartsCpt),
    ActivityTabPanelCpt: () => Promise.resolve(ActivityTabPanelCpt),
    TrackingAlertsCpt: () => Promise.resolve(TrackingAlertsCpt),
    ApiKeyPanelCpt: () => Promise.resolve(ApiKeyPanelCpt),
  },
})
export default class EditUserView extends AppVue {
  profile = new EditProfileModel();
  subscriptions: SubscriptionModel[] = [];
  loading: boolean = false;
  activeName = "first";
  dateFormat = "MMM Do YY";
  accountMigrationRules: { [key: string]: string } = {
    ONLINE: "CISONL",
    SELBD: "CISSBD",
    ATRIAL: "CISTRL",
    ICIS1: "CISCTY",
  };
  session: UserSessionModel = new UserSessionModel();
  showMigrateDialog = false;
  migrationResult: any = {};
  migrationComplete = false;
  migrationRunning = false;
  vatCodes: any[] = [];
  userStats = new UserAnalyticsStatsModel();
  userTrackedTotals: any[] = [];
  userSavedSearchesTotals: any[] = [];
  months = moment.monthsShort();
  years = [2019, 2020, 2021];
  selectedYear = moment().year();
  selectedMonth = moment().month();
  dailyStat = false;
  selectedChart = "bar";

  get isLoading() {
    return this.loading;
  }

  get canMigrateUser() {
    if (!this.profile.userId) {
      return false;
    }
    return _.filter(this.subscriptions, (o: SubscriptionModel) => {
      return o.canBeMigrated && !o.dateMigration;
    }).length;
  }

  get migrationResultValues() {
    return Object.entries(this.migrationResult);
  }

  async changeTab(model: any) {
    if (this.activeName === "stats") {
      this.selectedMonth = model.selectedMonth;
      this.selectedYear = model.selectedYear;
      this.dailyStat = model.dailyStat;
      this.userStats = await AccountService.getStats(
        this.profile.userId,
        this.dailyStat,
        this.selectedMonth,
        this.selectedYear,
      );
      this.userTrackedTotals = await AccountService.getUserTrackedTotals(this.profile.userId);
      this.userSavedSearchesTotals = await AccountService.getUserSavedSearches(this.profile.userId);
    }
  }
  transformSerie(rows: DateValue[]) {
    if (!rows) {
      return [];
    }
    const res = rows.map((r) => ({
      x: moment.utc(r.date).format(this.dateFormat),
      y: r.value,
    }));
    return res;
  }
  spaceLabel(s: string) {
    return s.replace(/([A-Z])/g, " $1").trim();
  }

  formUpdated(updateProfile: EditProfileModel) {
    this.profile = updateProfile;
  }

  saveCompleted(model: EditProfileModel) {
    this.profile = model;
    this.notify("User updated successfully", `${this.profile.emailAddress} had been saved`, "success");
  }

  canMigrateSubscription(pubCode: string, statusCode: string) {
    return !!this.accountMigrationRules[pubCode] && statusCode === "A";
  }

  async migrationPreview() {
    this.migrationRunning = true;
    const result = await SubscriptionService.migrateUser(this.profile.userId, true);
    this.migrationResult = result;
    this.notify("Migration test complete!", `No user data updated`, "warning");
    this.migrationRunning = false;
  }

  async migrate() {
    this.migrationRunning = true;
    try {
      const result = await SubscriptionService.migrateUser(this.profile.userId, false);
      this.migrationResult = result;
      await this.refresh();
      this.notify("Migration complete!", `User and subscription(s) have been successfully migrated`, "success");
      this.migrationComplete = true;
    } finally {
      this.migrationRunning = false;
    }
  }
  async refresh() {
    this.loading = true;
    const profileId = parseInt(this.$route.params.userProfileId, 10);
    this.profile = await AccountService.getProfileById(profileId);
    this.subscriptions = (await AccountService.getUserSubscriptions(profileId)).map((x: SubscriptionModel) => ({
      ...x,
      canBeMigrated: this.canMigrateSubscription(x.pubCode, x.statusCode),
    }));
    this.updateTitle(`${this.profile.name} ${this.profile.lastname}`);
    if (this.profile.userId) {
      this.session = await AccountService.getUserSession(this.profile.userId);
    }
    this.loading = false;
  }

  async impersonate(model: EditProfileModel) {
    const result = await AccountService.ImpersonateAccount(model.userProfileId);
    if (!!result) {
      this.$router.push("/").catch(() => {});
      window.location.reload();
    }
  }

  async sendInvitation(userProfileId: number) {
    const result = await AccountService.SendInvitation(userProfileId);
    if (!!result) {
      this.$notify({
        title: "Invitation sent",
        message: "Email confirmation request sent",
      });
    }
  }
  async created() {
    this.updateTitle("User profile");
    await this.refresh();
  }

  async mounted() {
    this.vatCodes = await SearchService.getLookupVatCodes();
    const end = moment().year();
    const start = 2019;

    this.years = [...Array(end - start + 1).keys()].map((x) => start + x);
  }
}
