





















import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class BehaviourReportsCpt extends AppVue {
  loading: boolean = false;
  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
