

































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, SessionRowModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection } from "@/core/models";

@Component({
  components: {},
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await (vm as ActiveUsersCpt).init();
    });
  },
})
export default class ActiveUsersCpt extends AppVue {
  @Prop({ default: "active" }) filter: string;
  results: PagedCollection<SessionRowModel> = new PagedCollection<SessionRowModel>();
  loading: boolean = false;
  initialized: boolean = false;
  get isLoading() {
    return this.loading;
  }

  async created() {
    if (!this.initialized) {
      await this.init();
    }
  }

  async closeAll() {
    this.loading = true;
    await AccountService.closeAll();
    await this.init();
  }
  async close(machineGuid: string) {
    this.loading = true;
    await AccountService.close(machineGuid);
    await this.init();
  }

  async init() {
    this.results = new PagedCollection<SessionRowModel>();
    this.loading = true;
    this.results = await this.q(this.filter);
    this.loading = false;
    this.initialized = true;
  }
  async q(query: string) {
    switch (query) {
      case "active":
        return await AccountService.whosonline();
      case "usage":
        return await AccountService.whousedit();
      default:
        return new PagedCollection<SessionRowModel>();
    }
  }
}
