




























































import { Component, Vue, Prop } from "vue-property-decorator";

import { CreditNoteModel, SubscriptionModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class CreditNoteTableCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: () => [] }) model: CreditNoteModel[];

  get isLoading() {
    return this.loading;
  }
  editCreditNote(creditNote: any) {
    this.$emit("editCreditNote", creditNote);
  }
  addCreditNote() {
    this.$emit("editCreditNote", new CreditNoteModel());
  }
  async created() {}

  async mounted() {}
}
